#map-canvas {

   .map-title {
      font-size: 48px;
      font-family: $lila-logo;
      color: #2E4E75;
      font-weight: bold;
      padding: 10px 10px 10px 0px;
   }
   .map-phone {
      font-family: $lila-body;
      color: #323232;
      font-weight: bold;
      font-size: 18px;
   }
   .map-address {
      font-family: $lila-body;
      color: #323232;
      font-weight: bold;
      font-size: 18px;

   }
}
