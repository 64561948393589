@charset "utf-8";

/* Copied normalize.csss to normalize-sass.scss */
@import "bower_components/normalize.css/normalize-sass";

// COLORS
@import "bower_components/crayon/dist/crayon";
@import "styles/stylesColors";
// 
// FONTS
// 
@import "custom-mixins/webfonts/webfonts";

// TYPOGRAPHIC
@import "bower_components/typographic/scss/typographic";
@import "styles/stylesTypographic";

// 
// LAYOUT
// 

// NEUTRON
// @import "custom-mixins/neutron/neutron";

// SUSY
@import "susy-config";
@import "bower_components/susy/sass/susy";

/*                               */
/* Material design frameworks    */
/*                               */
/* getmdli.io */
/* @import "bower_components/material-design-lite/src/material-design-lite"; */
/* materializecss.com */
/* @import "bower_components/materialize/sass/materialize"; */

/*  */
/* Layouts */
/*  */
@import 
       "layouts/layoutGlobal",
       "layouts/layoutHeader",
       "layouts/layoutFrontpage",
       "layouts/layoutBlog",
       "layouts/layoutFooter"
   ;


/*  */
/* Styles */
/*  */
@import 
      "styles/stylesColors",
      "styles/stylesGlobal",
      "styles/stylesBasicComponents",
      "styles/stylesHeader",      
      "styles/stylesFrontpage",
      "styles/stylesFooter",
      "styles/stylesPrices",
      "styles/stylesFrontpagenew",
      "styles/stylesFacilities",
      "styles/stylesGallery",
      "styles/stylesContact",
      "styles/stylesBlog",
      "styles/stylesMap" 
   ;
@import 

       "layouts/layoutNormal",
       "layouts/layoutNarrower",   
       "layouts/layoutMobile",
        "layouts/layoutWide"
   ;      
.gallery_new_section {
   .component-title  {
      padding: 2rem 2rem;
      margin-bottom: 0;
      color: #c1935c;
      font-size: 40px;
      line-height: 0.9em;
      letter-spacing: -3px;
      margin-left: -4px;
      margin-bottom: 0.3em;
   }
}
.gallery_new {
   width: 100%;
   padding: 0 2rem;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 1rem;

   a {
      @include susy-media(mobile) {
         width: 100%;
      }
      width: 30%;
      color:#fff;
      img {
      border: 3px;
      }
   }
   .img_title {
      box-shadow: 6px 6px 100px 1px rgba(0, 0, 0, 0.15);
      background: transparent;
      background: rgba(0, 0, 0, 0.15);
      padding: 0px 0px 0 80px;
      width: 100%;
      background: #323232;
      padding: 10px;
      opacity: 0.9;
   }
}

