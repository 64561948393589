/* MAP */
#map-canvas {
   width: 100%;
}
/* FOOTER */
.footer-wrapper {
   position: fixed;
   bottom: 0;
   width: 100vw;
   z-index: 3;
   padding: 5px 0;
}