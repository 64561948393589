.section-facilities {
   display: flex;  /* FLEX */
   text-align: left;
   .facilities-list {
      /*                @include gallery( 4 of 12); */
      display: flex; /* FLEX */
      flex-direction: column; /* FLEX */
      max-width: 280px;
      margin-left: 40px;
      background: #eeece1;
      padding: 25px 25px 20px;
      .title {
         color: #c1935c;
         font-size: 48px;
         line-height: .9em;
         letter-spacing: -3px;
         margin-left: -4px;
      }
      .title,
      .subtitle2 {
         margin-bottom: 15px; 
      }
      .subtitle1, .subtitle2 {
         line-height: 1.2em;
         color: #79797a;
      }
      .component-title {
         padding: 0 5px;
         margin-bottom: 0;
         color: #000;
         font-size: 19px;
         margin-bottom: 9px;
         padding-left: 15px;
      }
      ul {
         border: 1px solid #ccc;
         padding: 5px;
         background: #fff;
      }
      li {
         list-style: none;
         font-style: italic;
         color: #79797a;
         font-size: 15px;
      }
   }
} 