@include susy-media(normal) {
 
/* MIN MAX */
   .mainbar {
      max-width: 300px;
      min-width: 280px;
   }
   .sidebar {
     min-width: 220px;
   }
/* HEADER */
   
/* PAGE */
   .page {
      @include layout($symmetric);
      @include container;  
   }
/*    WITH BACKGROUND */

   .bg {
       background-size: cover; 
       background-position: 50% 50%;
/*        @include span( 10 of 12 last); */
       margin-left: 240px;
       height: 100vh;
   }
   .rates {
      height: auto;
   }
/*    WITH GALLERY */
   .gallery {
       height: 100%;
   }
/*   THREE COLUMNS ex. RATES  */
   .center {
      display: flex;
      align-items: center;
   }
/* MENU CONTAINER */
   .menu-container {
      display: none;
   }
/* FRONTPAGE */
   .content-gallery {
/*       @include span( 10 of 12 last ); */
      margin-left: 240px;
      min-width: 400px;
      height: 100%;
   }
/*    MAP */
   .content-nobg {
      margin-left: 240px;
   }
/* e.x. ACCOMODATION , Reservation, Contact */
   .mainbar {    
      @include layout( $symmetric );  
      @include span( 3 of 10 );
      margin-left: gutters(10);
      padding: 30px 20px;
   }
   .sidebar {
      @include layout( $symmetric );
      @include span( 3 of 10 ); 
      padding: 30px 25px 20px;
      max-width: 280px;
   }
   
   /* VILLAS GALLERY */
   .gallery-slideshow {
      height: 100%;
      .post-image {
         align-items: flex-start;
      }
   }
   .gallery-thumbnails {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      bottom: 33px;
      z-index: 52;
      background: #fff;
   }
/*    RATES */
   .section-prices {
       display: flex;
      flex-wrap: wrap;
   }
   .section-facilities {
      @include span( 10 of 12);
      max-width: 1050px;
      margin-left: gutters(12);
   }
   .section-suggestions {
      @include span( 85% );
      margin: 0 span(1);
      .main-suggestions {
         @include span( 9 of 12 );
      }
      .sidebar-suggestions {
         @include span( 3 of 12 last );
      }
      .scrollpsy-block {
         margin-top: 2em;
      }
   }
}