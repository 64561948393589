@font-face {
  font-family: "Material-Design-Icons";
  src: url("../fonts/material-design-icons/Material-Design-Icons.eot?#iefix") format("embedded-opentype"), url("../fonts/material-design-icons/Material-Design-Icons.woff2") format("woff2"), url("../fonts/material-design-icons/Material-Design-Icons.woff") format("woff"), url("../fonts/material-design-icons/Material-Design-Icons.ttf") format("truetype"), url("../fonts/material-design-icons/Material-Design-Icons.svg#Material-Design-Icons") format("svg");
  font-weight: normal;
  font-style: normal; }

// Z-levels
.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.z-depth-1-half{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.z-depth-2{
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-3{
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-4{
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.z-depth-5{
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

/*  */
/* FACILITIES */
/*  */
.full-height {
   height: 100%;
}
.mainbar {
   background: #EEECE1;
   @extend .z-depth-2;
}
.facilities {

   .title {
      padding: 0 5px;
      margin-bottom: 0;
      color: #c1935c;
      font-size: 40px;
      line-height: .9em;
      letter-spacing: -3px;
      margin-left: -4px;
      margin-bottom: 0.3em;
   }
   .subtitle1,
   .subtitle2 {
      color: #79797a;
      font-size: 17px;
      line-height: 20px;
   }
   .subtitle2 {
      margin-bottom: 20px;
   }
   .component-title {
      padding: 0 5px;
      margin-bottom: 0;
      color: #000;
      font-size: 19px;
      margin-bottom: 9px;
      padding-left: 15px;
   }
   ul {
      border: 1px solid #ccc;
      padding: 5px;
      background: #fff;
   }
   li {
      padding: 5px 10px;
      list-style: none;
      font-style: italic;
      color: #79797a;
      font-size: 15px;
      line-height: 0.9em;
   }
}

.sidebar {
}
/* LINKS */
.links {
   margin-bottom: 3em;
   li {
      @extend .z-depth-1;
      background: transparent;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 10px;
   }
   a {
      padding: 5px 25px;
      color: #fff;
      background: #77933C;
      display: block;
   }
   a:hover {
      background: #94B946;
      color: #323232;
   }
   // tried to add materialize css buttons
   // ul li.bungalow {
   //      .link-wrapper{
   //        display: flex;
   //         padding: 5px 15px;
   //         align-items: center;
   //         background: rgba(119, 147, 60, 1);
   //      }
   //      .waves-effect.waves-greenlila .waves-ripple {
   //           The alpha value allows the text and background color
   //           of the button to still show through.
   //           background-color: rgba(119, 147, 60, 0.65);
   //        }
   //        .btn:hover, .btn-large:hover {
   //            background-color: #8dab50;
   //        }
   //       a {
   //          padding: 0;
   //          color: #fff;
   //          display: block;
   //       }
   //       a:hover {
   //          color: #fff;
   //       }
   // }
}
.suggestions {
   .section-header {
    background: #eeece1;
    line-height: 4rem;
    font-size: 42px;
    padding: 10px;
    color: #c1935c;
    font-weight: bold;
   }
   .post {
      margin-bottom: 40px;
   }
   .post-image {
      margin-bottom: 15px;
   }
   .title {
      font-size: 34px;
      font-weight: bold;
      color: #2E4E75;
   }
}
.scrollpsy-block {
   a {
		padding-left: 20px;
      color: #2e4e75;
	}
   a:hover {
      border-left: 1px solid #F44336;
		padding-left: 19px;
      color: #768CA7;
   }
	a.active {
		border-left: 3px solid #F44336;
		padding-left: 18px;
	   line-height: 2;
      font-weight: bold;
	}
}
/*  */
/* RATES */
/*  */
.component-title.rate {
   background: #C4BD97;
   color: #000;
   font-size: 17px;
   padding: 0px 5px;
   margin-bottom: 5px;
}
