.menu-container {
   background: #2E4E75;
}
.side-nav.fixed {
/*    background: #4979B3;
   background: #538ED5;  */
   background: #2E4E75;
   li.logo:hover,
   li.logo.active {
      background: transparent;
   }   
  .logo {
      padding: 30px 10px;
      text-align: center;            
      a {
         color: #fff;
         font-size: 48px;
         font-family: $lila-logo;
         height: auto;
         padding: 0;
         }
      }
   .logo-title {
      font-size: 2em;
      line-height: 0.8em;
      }
   .logo-subtitle {
      font-size: 0.8em;
      }   
   li.menu-item:hover {
         background: #1C3048;
      }   
   li.menu-item.active {
         background: #77933C;
      }   
   a {
      color: #f4f0f0;
      display: block;
      display: block;
      font-size: 18px;
      padding: 10px;
      line-height: 1em;
      height: auto;
   }
   .waves-btn .waves-ripple { 
      /* The alpha value allows the text and background color
     of the button to still show through. */
      background-color: rgba(73, 121, 179, 0.65);
    }

   .menu-title {
      color: #efefef;
      padding: 0px;
      line-height: 1.3em;
      font-size: 19px;
      .collapsible li:hover{
         background: #1C3048; 
      }
      .collapsible li.active {
          background: #1C3048;
      }
       .collapsible-body li:hover{
         background: #1C3048;  
      }
       .collapsible-body li.active {
         color: #d8c6bc;
         background: #77933C;
      }   
   }
   .submenu-item {
      text-align: right;
      background: #4571A7;
   }
}                 
.side-nav .collapsible-body li a {
    margin: 0;
}            
.side-nav .collapsible-body li.active {
    background: #2E4E75;
}      