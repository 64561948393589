/* RATES PAGE */
.frontpage .bg {
   background: #aac5e6;
}
.section-frontpagenew {   
   text-align: left;
   display: flex;
   flex-wrap: wrap;
      .details {
         
         color: #C1935C;
      }
      .frontpagenew-list {
            // max-width: 280px;
            // min-width: 350px;
            display: flex; 
            // flex:1;
            flex-direction: column; 
            background: transparent;
            // padding: 25px 25px 20px;
            color: #fff;
            height: 49vh;
            width: 50%;
            // margin-left: gutters(12); 
            //     margin-bottom: 2rem;
         a.title {
            color: #203650;
            font-size: 48px;
            line-height: .9em;
            letter-spacing: -3px;
            margin-left: -4px;
            flex-grow: 1; 
            background: rgba(204, 204, 204, 0.5);
         }
         a.title:hover,a.title:focus {
            background: #203650;
            color: #fff;
         }
         .details-subtitle {
            background-color: #C4BD97;
         }
         .subtitle1, .subtitle2 {
            // line-height: 1.2em;
            font-size: 1.2rem;
            color: #fff;
         }
         .title,
         .subtitle2 {
            color: #fff;
         }
         ul li {
            display: flex;
                flex-wrap: wrap;
            justify-content: space-between;
            background: transparent;
            
         }
         .season {
             // font-size: 22px;
             // margin-bottom: 8px;

         }
         .details-price {
            background-color: #fff;
               // border: 1px solid #C1935C;
         }
         .price {            
            // font-size: 24px;
            // padding: 5px 8px;
            // line-height: 1.2;
            // overflow: hidden;
            

         }
         .date {
            @include span( 100% );
            font-size: 13px;
            line-height: 1.5em;
         }
         .date2 {
           @include span( 50% last );
            margin-bottom: 20px;
         }
         .more {
            color: #fff;
            text-align: center;
            .waves-effect.waves-green .waves-ripple {
           /* The alpha value allows the text and background color
           of the button to still show through. */
            background-color: rgba(121, 85, 72, 0.65);
    }
         }
   
}
}