   section.content-gallery,
   .frontpage-slideshow,
   .slideshow-item,
   .post-image {
      height: 100%;
   }
   .owl-carousel,
   .owl-stage-outer,
   .owl-stage,
   .owl-item {
     height: 100% !important;    
   }
   .post-image {
       display: flex;
       align-items: flex-end;
       font-size: 48px;
       color: #FFF;
       background-size: cover;
       background-position: 50% 50%;       
   }
   .slideshow-title {
       box-shadow: 0 0px 100px 71px rgba(0, 0, 0, 0.15);
       background: transparent;
       background: rgba(0, 0, 0, 0.15);
       margin: 0 0 15rem 80px;
       line-height: 1em;
       font-size: 64px;
   }