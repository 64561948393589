@include susy-media(mobile) {
  .page {
      @include layout($full-width);
      @include container;  
  }
   .bg {
/*        background: none; */
   }
   .gallery {
       height: 100%;
   }
   .center {
      display: block;
   }
   
/* MOBILE NAV */
   .menu-container {
      @include span( 12 of 12);
      display: flex;
      align-items: center;
      .button-collapse {
         @include span( 3 of 12);
         text-align: center;
         color: #fff;
         font-size: 32px;
      }
      .logo {
         @include span( 9 of 12 last);
         .logo-title {
             font-size: 1.2em;
             text-align: left;
         }
      }
        .logo {
         padding: 10px 0px;
         text-align: center;            
         a {
            color: #fff;
            font-size: 24px;
            font-family: $lila-logo;
            line-height: 1.2;
            height: auto;
            }
         }
         .logo-title {
            font-size: 2em;
         }
         .logo-subtitle {
            font-size: 0.8em;
         }
   }
 
   
   
   /* CONTENT */
   .content-gallery {
      @include span( 12 of 12 );
      height: auto;
   }
   .content-nobg {
      @include span( 12 of 12 last);
      @include push( 2 of 12 );
   }
   .mainbar {    
      @include layout( $symmetric );  
      @include span( 12 of 12 );
      padding: 30px 20px;
   }
   .sidebar {
      @include layout( $symmetric );
      @include span( 12 of 12 ); 
      padding: 30px 25px 20px;
   }
   
/*  FRONTPAGE */
   .slideshow-title {
      font-size: 24px;
      padding: 0 10px;
      margin: 0 0 15rem 0px;
   }
   /* VILLAS GALLERY */
   .gallery-slideshow {
      @include span( 12 of 12 );  
      height: 100%;
      .post-image {
         align-items: flex-start;
      }
   }
   .owl-carousel {
      @include span( 12 of 12 ); 
   }
   .gallery-thumbnails {
      @include span( 12 of 12 ); 
      display: flex;
      flex-wrap: wrap;
   }
/* ACOOMODATION */
   .section-facilities {
      @include span( 12 of 12);
   }
   .full-height {
    height: auto;
   }
   
/*    RATES */
   .section-prices {
      @include span( 12 of 12);
   }
   .section-prices {
      display: block;
      margin-top: 20px;
   }
   .section-prices .prices-list {
   /* background: #ececec;
    color: #323232; */
    margin-bottom: 20px;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 20px;
   }
   .more {
      color: #323232;
   }


// FRONTPAGENEW

.section-frontpagenew {
   width: 100%;
   display: flex;
    justify-content: center;
   .frontpagenew-list  {
      width: 100%;
      margin-bottom: 1rem;
   }
}



/* RESERVATION - CONTACT */
   .contact-form {
      @include span( 12 of 12 );
   }
   .contact-details {
      @include span( 12 of 12 );
      padding: 30px 25px 20px;
   }
   /* MAP */
   #map-canvas {
      width: 100%;
   }
   /* FOOTER */
   .footer-wrapper {
      @include span( 12 of 12 ); 
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center; 
   }
   .footer {
      display: block;
   .logo {
      }
   }
   .contact-footer {
      display: block;
      .field {
         color: #999;
         font-weight: normal;      
         font-size: 13px;
         padding: 0 20px;
         line-height: 1.75em;
      }
   }
   .footer-gallery {
       display: none;
   }
}