.success-message {
   display: none;
}
.contact-form-materialize {
   .component-title {
      color: #c1935c;
      font-size: 48px;
      line-height: .9em;
      letter-spacing: -3px;
      margin-bottom: 20px;
   }
}
textarea {
    overflow-y: hidden;
    padding: 1.6rem 0;
    resize: none;
    min-height: 3rem;
}
/*  */
/* RESERVATION */
/*  */
.checkout,
.villa {

}
.checkin,
.persons {

}
.message {
    @include span(100%);
}
.contact-details {
/*       max-width: 280px;
      min-width: 220px; */
      background: #C4BD97;
      color: #79797a;
      font-size: 17px;
      line-height: 21px;
      font-weight: normal;
   .label {
       font-size: 17px;
       color: #323232;      
   }
   .phone,
   .email,
   .address,
   .gps {
      margin-bottom: 15px;
      font-size: 15px;
      color: #fff;
   }
}
/* OVERIDE PARSLEY */
.parsley-errors-list li {
    color: #f44336;
    opacity: 1;
    font-size: 0.8em;
}
/* OVERIDE FORM MATERIALIZE */
input[type="text"], input[type="password"], input[type="email"], input[type="url"], input[type="time"], input[type="date"], input[type="datetime-local"], input[type="tel"], input[type="number"], input[type="search"], textarea.materialize-textarea {
   margin-bottom: 5px;
}