html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html,body {
    height: 100%;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    font-weight: 500;
    font-family: $lila-body;
}

img {
   width:100%;
   display: block;
}

h1, h2, h3, h4, h5, h6 {
}

a, p, li, input, textarea, span, div {
}

ul, menu, dir {
   margin: 0;
   padding: 0;
   list-style: none;
}
ul li {
   list-style: none;
   list-decoration: none;
}
a {
    text-decoration: none;
    line-height: inherit;
    background-color: transparent;
}
